const actions = {
  SET_STATE: 'resources/SET_STATE',
  GET_SERVER_INFO: 'resources/GET_SERVER_INFO',
  SET_PROVIDER_NOTES: 'resources/SET_PROVIDER_NOTES',
  UPDATE_PRICES: 'resources/UPDATE_PRICES',
  UPDATE_SESSION: 'resources/UPDATE_SESSION',
  UPDATE_URL: 'resources/UPDATE_URL',
  DELETE_PROVIDER_NOTE: 'resources/DELETE_PROVIDER_NOTE',
  UPGRADE_PROVIDER_VERSION: 'resources/UPGRADE_PROVIDER_VERSION',
  RESTART_PROVIDER: 'resources/RESTART_PROVIDER',
  UPDATE_ATTRIBUTES: 'resources/UPDATE_ATTRIBUTES',
  GET_PROVIDER_VERSION: 'resources/GET_PROVIDER_VERSION',
}

export default actions
