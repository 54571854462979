const actions = {
  SET_STATE: 'user/SET_STATE',
  WALLET_CONNECT: 'user/WALLET_CONNECT',
  CHECK_MASTER_NODE_ACCESS: 'user/CHECK_MASTER_NODE_ACCESS',
  VERIFY_AKASH_WALLET: 'user/VERIFY_AKASH_WALLET',
  PROVIDER_SETTINGS: 'user/PROVIDER_SETTINGS',
  GET_SESSION_STATUS: 'user/GET_SESSION_STATUS',
  DASHBOARD: 'user/DASHBOARD',
  GET_NOUNCE: 'user/NOUNCE',
  VERIFY_SIGNATURE: 'user/VERIFY_SIGNATURE',
  LOGOUTUSER: 'user/LOGOUTUSER',
  REFRESH: 'user/REFRESH',
  START_OVER: 'user/START_OVER',
}

export default actions
